/* eslint-disable no-restricted-syntax */
import firebase from 'firebase/app'
import 'firebase/firestore'

const blankModel = {
  id: '',
  created_at: null,
  nombre: '',
  color: '#e42121',
  description: '',
  active: true,
  status: 0,
  rider_id: '',
  rider_nombre: '',
  vehiculo_id: '',
  vehiculo_tipo: '',
  vehiculo_capacidad: 0,
  notas: '',
}
let unsubscribe = null
let unsubscribeRider = null
let unsubscribeRiderPedidos = null
export default {
  namespaced: true,
  state: {
    rutas: [],
    rutasRider: [],
    pedidosToRuta: [],
    riderRutaOpen: null,
    fechaDesdeLocal: null,
    fechaHastaLocal: null,
  },
  getters: {
    rutaModel() {
      return blankModel
    },
  },
  mutations: {
    SET_RIDER_RUTA_OPEN(state, payload) {
      state.riderRutaOpen = payload
    },
    SET_RUTAS(state, payload) {
      state.rutas = payload
    },
    SET_RUTAS_RIDER(state, payload) {
      state.rutasRider = payload
    },
    SET_FECHA_DESDE(state, payload) {
      state.fechaDesdeLocal = payload
    },
    SET_FECHA_HASTA(state, payload) {
      state.fechaHastaLocal = payload
    },
    SET_PEDIDOS_TO_RUTA(state, payload) {
      state.pedidosToRuta = payload
    },
  },
  actions: {
    getPedidosToRuta({ dispatch, commit }, ruta) {
      const idRuta = ruta.id
      commit('SET_RIDER_RUTA_OPEN', ruta)
      const db = firebase.firestore()
      if (unsubscribeRiderPedidos) unsubscribeRiderPedidos()
      unsubscribeRiderPedidos = db.collection('pedidos')
        .where('ruta', '==', idRuta)
        .onSnapshot(querySnapshot => {
          const items = []
          querySnapshot.forEach(doc => {
            const pedido = doc.data()
            items.push(pedido)
            // Verifica que los pedidos están en reparto (status = 7) o entregados (status = 15)
            if (pedido.status < 7) {
              dispatch('updatePedidoStatus', {
                id: pedido.id,
                status: 7,
              })
            }
          })
          commit('SET_PEDIDOS_TO_RUTA', items)
        }, error => {
          // console.log(error)
          throw new Error(error)
        })
    },
    pedidoEntregado({ state, dispatch }, payload) {
      const ruta = state.rutasRider.filter(r => r.id === payload.idRuta)[0]
      const { pedidosToRuta } = state
      if (!ruta) {
        console.log('Error: Ruta no encontrada')
        return
      }
      let pedidosDespachados = 0
      pedidosToRuta.forEach(pedido => {
        const { status } = pedido
        if (status === 15) pedidosDespachados += 1
      })
      // Guarda el nuevo status del pedido entregado (o no entregado pero despachado)
      const pedido = pedidosToRuta.filter(p => p.id === payload.idPedido)[0]
      pedido.status = 15
      pedido.delivered_at = new Date().getTime()
      pedido.deliveredDetails = payload.entregaDetalles
      dispatch('updatePedido', pedido)
      let rutaIsEnded = false
      if (pedidosDespachados >= pedidosToRuta.length - 1) rutaIsEnded = true
      if (rutaIsEnded) {
        ruta.status = 15 // Cambiado de 3 a 15
        ruta.ended_at = new Date().getTime()
        dispatch('updateRuta', ruta)
      }
    },

    updatePedido(ctx, pedido) {
      return new Promise((resolve, reject) => {
        const db = firebase.firestore()
        db.collection('pedidos')
          .doc(`${pedido.id}`)
          .update(pedido)
          .then(() => {
            // console.log(pedido.id)
            resolve(true)
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    getRutasRider({ commit }, idRider) {
      const fechaDesde = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 5).getTime()
      const fechaHasta = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1).getTime()
      const db = firebase.firestore()
      if (unsubscribeRider) return
      unsubscribeRider = db.collection('rutas')
        .where('active', '==', true)
        .where('rider_id', '==', idRider)
        .where('created_at', '>=', fechaDesde)
        .where('created_at', '<=', fechaHasta)
        .onSnapshot(querySnapshot => {
          const items = []
          querySnapshot.forEach(doc => {
            items.push(doc.data())
          })
          commit('SET_RUTAS_RIDER', items)
        }, error => {
          // console.log(error)
          throw new Error(error)
        })
    },
    getRutas({ rootState, state, commit }) {
      const { fechaDesde, fechaHasta } = rootState.app
      const { fechaDesdeLocal, fechaHastaLocal } = state
      if (unsubscribe) {
        if (fechaDesdeLocal === fechaDesde && fechaHastaLocal === fechaHasta) return
        unsubscribe()
      }
      // console.log('fechaDesde: ', fechaDesde)
      // console.log('fechaHasta: ', fechaHasta)
      commit('SET_FECHA_DESDE', fechaDesde)
      commit('SET_FECHA_HASTA', fechaHasta)
      // if (unsubscribe) return
      // const fechaHoy = new Date().toDateString()
      // const condicion = new Date(fechaHoy).getTime()
      const db = firebase.firestore()
      unsubscribe = db.collection('rutas')
        // .where('created_at', '>', condicion)
        .where('active', '==', true)
        .where('created_at', '>=', fechaDesde)
        .where('created_at', '<=', fechaHasta)
        .onSnapshot(querySnapshot => {
          const items = []
          querySnapshot.forEach(doc => {
            items.push(doc.data())
          })
          commit('SET_RUTAS', items)
        }, error => {
          // console.log(error)
          throw new Error(error)
        })
    },

    addRuta(ctx, payload) {
      return new Promise((resolve, reject) => {
        const db = firebase.firestore()
        db.collection('rutas')
          .doc(`${payload.id}`)
          .set(payload)
          .then(() => {
            // console.log(pedido.id)
            resolve(true)
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    updatePedidoStatus(ctx, payload) {
      return new Promise((resolve, reject) => {
        const db = firebase.firestore()
        db.collection('pedidos')
          .doc(`${payload.id}`)
          .update({ status: payload.status })
          .then(() => {
            // console.log(pedido.id)
            resolve(true)
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    updateRuta(ctx, ruta) {
      return new Promise((resolve, reject) => {
        const db = firebase.firestore()
        db.collection('rutas')
          .doc(`${ruta.id}`)
          .update(ruta)
          .then(() => {
            // console.log(pedido.id)
            resolve(true)
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    pedidoToRuta(ctx, payload) {
      return new Promise((resolve, reject) => {
        const db = firebase.firestore()
        db.collection('pedidos')
          .doc(`${payload.pedido.id}`)
          .update({
            ruta: payload.ruta,
            status: 3,
          })
          .then(() => {
            // console.log(pedido.id)
            resolve(true)
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    deleteRuta(ctx, id) {
      return new Promise((resolve, reject) => {
        const db = firebase.firestore()
        db.collection('rutas')
          .doc(`${id}`)
          .delete()
          .then(() => {
            // console.log(pedido.id)
            resolve(true)
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    UNSUSCRIBE() {
      if (unsubscribe) unsubscribe()
      if (unsubscribeRider) unsubscribeRider()
      if (unsubscribeRiderPedidos) unsubscribeRiderPedidos()
    },
  },
}
