import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import Vue from 'vue'
import router from '@/router'

const guestAccess = {
  userId: '',
  nombre: 'guest',
  rules: [
    {
      action: 'read',
      subject: 'ACL',
    },
    {
      action: 'read',
      subject: 'Auth',
    },
  ],
}
export default {
  namespaced: true,
  state: {
    dataUser: guestAccess,
    isUserLoggedIn: () => {
      let result = false
      // get firebase current user
      const firebaseCurrentUser = firebase.auth().currentUser
      if (firebaseCurrentUser) result = true
      return result
    },
    isLogged: false,
  },
  getters: {
    rules(state) {
      return state.dataUser.rules
    },

    firebaseUser() {
      if (firebase) return firebase.auth().currentUser
      return false
    },
  },
  mutations: {
    createSession(state, session) {
      state.isLogged = true
      state.dataUser = session
      const dataUser = session
      localStorage.setItem('userData', JSON.stringify(session))
      // const ability = new Ability()
      // ability.update(dataUser.rules)
      Vue.prototype.$ability.update(dataUser.rules)
      const { rutaName } = session
      router.replace({ name: rutaName || '/' }).catch(() => {})
    },

    destroySession(state) {
      // console.log('saliendo')
      state.dataUser = guestAccess
      localStorage.removeItem('userData')
      // localStorage.setItem('userData', JSON.stringify(guestAccess))
      // Vue.prototype.$ability.update(guestAccess.rules)
      router.replace({ name: 'auth-login' }).catch(() => {})
      // localStorage.removeItem('userData')
      // router.replace({ name: 'auth-login' }).catch(() => {})
    },
    SET_IS_LOGGED(state, payload) {
      state.isLogged = payload
    },
  },
  actions: {
    checkIsLogin({ state, commit }) {
      // console.log('checkIsLogin')
      const firebaseCurrentUser = firebase.auth().currentUser
      // console.log(firebaseCurrentUser)
      if (!firebaseCurrentUser) {
        commit('destroySession')
        return
      }
      if (firebaseCurrentUser.uid === state.dataUser?.userId) {
        commit('createSession', state.dataUser)
        return
      }
      const dbFS = firebase.firestore()
      dbFS
        .collection('users')
        .doc(`${firebaseCurrentUser.uid}`)
        .get()
        .then(doc => {
          if (doc.exists) {
            const datos = doc.data()
            // console.log('Document data:', datos)
            // const data = {
            //   userId: datos.id,
            //   nombre: datos.nombre || '',
            // }
            datos.userId = datos.id
            commit('createSession', datos)
          } else {
            // doc.data() will be undefined in this case
            // console.log(`El usuario ${firebaseCurrentUser.uid}, ${firebaseCurrentUser.email}, no se encuentra en la base de datos`)
            commit('createSession', guestAccess)
            // commit('guestSession')
          }
        })
        .catch(() => {
          // console.error('Error accediendo al documento:', error)
          commit('createSession', guestAccess)
        })
    },

    readItemsDataBase(payload) {
      const { collection, document } = payload
      const dbFS = firebase.firestore()
      return new Promise((resolve, reject) => {
        dbFS
          .collection(collection)
          .doc(document)
          .get()
          .then(doc => {
            if (doc.exists) {
              const datos = doc.data()
              // console.log('Document data:', datos)
              const result = {
                nameList: `${collection}List`,
                datosList: datos,
              }
              resolve(result)
            } else {
              resolve({})
            }
          })
          .catch(error => {
            // console.error('Error accediendo al documento:', error)
            reject(error)
          })
      })
    },

    login({ commit }, payload) {
      const { email, password } = payload.userDetails
      // console.log(payload)
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() => {
          firebase.auth().signInWithEmailAndPassword(email, password)
            .then(() => {
              commit('SET_IS_LOGGED', true)
            }, () => {
              // console.error(err)
            })
        })
        .catch(() => {
          // console.error(err.code)
          // console.error(err.message)
        })
      if (!payload.checkbox_remember_me) {
        // Change firebase Persistence
      } else {
        // Try to login
        // dispatch('login', newPayload)
      }
    },
    logout() {
      // commit('destroySession')
      firebase.auth().signOut()
    },
  },
}
