export default [
  // *===============================================---*
  // *--------- CLIENTES -------------------------------*
  // *===============================================---*
  {
    path: '/bimbo/clientes/list',
    name: 'bimbo-clientes-list',
    component: () => import('@/views/bimbo/clientes/clientes-list/ClientsList.vue'),
    meta: {
      resource: 'Auth',
      action: 'write',
    },
  },
  {
    path: '/bimbo/clientes/view/:id',
    name: 'bimbo-clientes-view',
    component: () => import('@/views/bimbo/clientes/clientes-view/ClientesView.vue'),
    meta: {
      resource: 'Auth',
      action: 'write',
    },
  },
  {
    path: '/bimbo/clientes/edit/:id',
    name: 'bimbo-clientes-edit',
    component: () => import('@/views/bimbo/clientes/clientes-edit/ClientesEdit.vue'),
    meta: {
      resource: 'Auth',
      action: 'write',
    },
  },

  // *===============================================---*
  // *--------- PEDIDOS --------------------------------*
  // *===============================================---*
  {
    path: '/bimbo/pedidos/pickingv2',
    name: 'bimbo-pedidos-picking-v2',
    component: () => import('@/views/bimbo/pedidos/pickingv2/Picking.vue'),
    meta: {
      resource: 'Auth',
      action: 'write',
      view: 'list',
    },
  },
  {
    path: '/bimbo/pedidos/pickingv2/item/:id',
    name: 'bimbo-pedidos-picking-v2-item',
    component: () => import('@/views/bimbo/pedidos/pickingv2/PickingPedidoItem.vue'),
    meta: {
      resource: 'Auth',
      action: 'write',
    },
  },
  {
    path: '/bimbo/pedidos/list',
    name: 'bimbo-pedidos-list',
    component: () => import('@/views/bimbo/pedidos/pedidos-list/PedidosList.vue'),
    meta: {
      resource: 'Auth',
      action: 'write',
      view: 'list',
    },
  },
  {
    path: '/bimbo/pedidos/picking',
    name: 'bimbo-pedidos-picking',
    component: () => import('@/views/bimbo/pedidos/picking/Picking.vue'),
    meta: {
      resource: 'Auth',
      action: 'write',
      view: 'picking',
    },
  },
  {
    path: '/bimbo/pedidos/pending',
    name: 'bimbo-pedidos-pending',
    component: () => import('@/views/bimbo/pedidos/pedidos-all/PedidosAll.vue'),
    meta: {
      resource: 'Auth',
      action: 'write',
      view: 'Pending',
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
  },
  {
    path: '/bimbo/pedidos/picked',
    name: 'bimbo-pedidos-picked',
    component: () => import('@/views/bimbo/pedidos/pedidos-all/PedidosAll.vue'),
    meta: {
      resource: 'Auth',
      action: 'write',
      view: 'Picked',
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
  },
  {
    path: '/bimbo/pedidos/routed',
    name: 'bimbo-pedidos-routed',
    component: () => import('@/views/bimbo/pedidos/pedidos-all/PedidosAll.vue'),
    meta: {
      resource: 'Auth',
      action: 'write',
      view: 'Routed',
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
  },
  {
    path: '/bimbo/pedidos/dispatched',
    name: 'bimbo-pedidos-dispatched',
    component: () => import('@/views/bimbo/pedidos/pedidos-all/PedidosAll.vue'),
    meta: {
      resource: 'Auth',
      action: 'write',
      view: 'Dispatched',
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
  },
  {
    path: '/bimbo/pedidos/delivered',
    name: 'bimbo-pedidos-delivered',
    component: () => import('@/views/bimbo/pedidos/pedidos-all/PedidosAll.vue'),
    meta: {
      resource: 'Auth',
      action: 'write',
      view: 'Delivered',
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
  },
  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  // {
  //   path: '/bimbo/pedidos/all',
  //   name: 'bimbo-pedidos-all',
  //   component: () => import('@/views/bimbo/pedidos/pedidos-all/PedidosAll.vue'),
  //   meta: {
  //     resource: 'Auth',
  //     action: 'write',
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'todo-application',
  //   },
  // },
  // {
  //   path: '/bimbo/pedidos/all/:filter',
  //   name: 'bimbo-pedidos-filter',
  //   component: () => import('@/views/bimbo/pedidos/pedidos-all/PedidosAll.vue'),
  //   meta: {
  //     resource: 'Auth',
  //     action: 'write',
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'todo-application',
  //     navActiveLink: 'bimbo-pedidos-all',
  //   },
  //   beforeEnter(to, _, next) {
  //     if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
  //     else next({ name: 'error-404' })
  //   },
  // },
  // {
  //   path: '/bimbo/pedidos/all/tag/:tag',
  //   name: 'bimbo-pedidos-tag',
  //   component: () => import('@/views/bimbo/pedidos/pedidos-all/PedidosAll.vue'),
  //   meta: {
  //     resource: 'Auth',
  //     action: 'write',
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'todo-application',
  //     navActiveLink: 'bimbo-pedidos-all',
  //   },
  //   beforeEnter(to, _, next) {
  //     if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
  //     else next({ name: 'error-404' })
  //   },
  // },
  // {
  //   path: '/bimbo/pedidos/view/:id',
  //   name: 'bimbo-pedidos-view',
  //   component: () => import('@/views/bimbo/pedidos/users-view/UsersView.vue'),
  // },
  // {
  //   path: '/bimbo/pedidos/edit/:id',
  //   name: 'bimbo-pedidos-edit',
  //   component: () => import('@/views/bimbo/pedidos/users-edit/UsersEdit.vue'),
  // },
  // *===============================================---*
  // *--------- PRODUCTOS --------------------------------*
  // *===============================================---*
  {
    path: '/bimbo/productos/list',
    name: 'bimbo-productos-list',
    component: () => import('@/views/bimbo/productos/productos-list/ProductosList.vue'),
    meta: {
      resource: 'Auth',
      action: 'write',
    },
  },
  {
    path: '/bimbo/productos/inventory',
    name: 'bimbo-inventarios-list',
    component: () => import('@/views/bimbo/productos/inventarios/InventariosList.vue'),
    meta: {
      resource: 'Auth',
      action: 'write',
    },
  },
  {
    path: '/bimbo/productos/inventory/view/:id',
    name: 'bimbo-inventario-view',
    component: () => import('@/views/bimbo/productos/inventarios/InventarioView.vue'),
    meta: {
      resource: 'Auth',
      action: 'write',
      navActiveLink: 'bimbo-inventarios-list',
    },
  },
  {
    path: '/bimbo/productos/inventory/new',
    name: 'bimbo-inventario-new',
    component: () => import('@/views/bimbo/productos/inventarios/InventarioNew.vue'),
    meta: {
      resource: 'Auth',
      action: 'write',
      navActiveLink: 'bimbo-inventarios-list',
    },
  },
  {
    path: '/bimbo/productos/entradas',
    name: 'bimbo-entradas-list',
    component: () => import('@/views/bimbo/productos/entradas/EntradasList.vue'),
    meta: {
      resource: 'Auth',
      action: 'write',
    },
  },
  {
    path: '/bimbo/productos/entradas/view/:id',
    name: 'bimbo-entradas-view',
    component: () => import('@/views/bimbo/productos/entradas/EntradaView.vue'),
    meta: {
      resource: 'Auth',
      action: 'write',
      navActiveLink: 'bimbo-entradas-list',
    },
  },
  {
    path: '/bimbo/productos/entradas/new',
    name: 'bimbo-entradas-new',
    component: () => import('@/views/bimbo/productos/entradas/EntradasNew.vue'),
    meta: {
      resource: 'Auth',
      action: 'write',
      navActiveLink: 'bimbo-entradas-list',
    },
  },

  // *===============================================---*
  // *--------- SHOP -----------------------------------*
  // *===============================================---*
  {
    path: '/bimbo/shop',
    name: 'bimbo-shop',
    component: () => import('@/views/bimbo/shop/Shop.vue'),
    meta: {
      resource: 'Shop',
      action: 'write',
      // contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'Shop',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Shop',
          active: true,
        },
      ],
    },
  },
  {
    path: '/bimbo/e-commerce/checkout',
    name: 'bimbo-e-commerce-checkout',
    component: () => import('@/views/bimbo/shop/e-commerce-checkout/ECommerceCheckout.vue'),
    meta: {
      resource: 'Shop',
      action: 'write',
      pageTitle: 'Checkout',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Checkout',
          active: true,
        },
      ],
    },
  },
  {
    path: '/bimbo/shop-list',
    name: 'bimbo-shop-list',
    component: () => import('@/views/bimbo/shop/PedidosList.vue'),
    meta: {
      resource: 'Shop',
      action: 'write',
      // contentRenderer: 'sidebar-left-detached',
      // contentClass: 'ecommerce-application',
      pageTitle: 'Shop List',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Shop',
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *--------- TICKET -----------------------------------*
  // *===============================================---*
  {
    path: '/bimbo/pedidos/ticket/:id',
    name: 'bimbo-pedidos-ticket',
    component: () => import('@/views/bimbo/pedidos/pedidos-all/TicketPrintPage.vue'),
    meta: {
      resource: 'Auth',
      action: 'write',
      navActiveLink: 'bimbo-pedidos-picked',
      // layout: 'full',
    },
  },

  // *===============================================---*
  // *--------- RIDER ----------------------------------*
  // *===============================================---*
  {
    path: '/bimbo/rider',
    name: 'bimbo-rider',
    component: () => import('@/views/bimbo/rider/Rider.vue'),
    meta: {
      resource: 'Rider',
      action: 'write',
      pageTitle: 'Rider',
      // breadcrumb: [
      //   {
      //     text: 'ECommerce',
      //   },
      //   {
      //     text: 'Shop',
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: '/bimbo/rider/delivering',
    name: 'bimbo-delivering',
    component: () => import('@/views/bimbo/rider/RutaDelivering.vue'),
    meta: {
      resource: 'Rider',
      action: 'write',
      pageTitle: 'Entregas',
      isVolver: true,
    },
  },
  {
    path: '/bimbo/rider/delivering/:id',
    name: 'bimbo-delivering-item',
    component: () => import('@/views/bimbo/rider/RutaDeliveringItem.vue'),
    meta: {
      resource: 'Rider',
      action: 'write',
      pageTitle: 'Entregando',
      isVolver: true,
    },
  },
]
