/* eslint-disable no-restricted-syntax */
import firebase from 'firebase/app'
import 'firebase/firestore'

const blankVehiculo = {
  id: '',
  tipo: 'electric',
  nombre: '',
  description: '',
  active: true,
  capacidadUnidadesTransporte: 0,
  notas: '',
}
let unsubscribe = null
export default {
  namespaced: true,
  state: {
    vehiculos: [],
  },
  getters: {},
  mutations: {
    SET_VEHICULOS(state, payload) {
      state.vehiculos = payload
    },
  },
  actions: {
    getVehiculos({ commit }) {
      if (unsubscribe) return
      const db = firebase.firestore()
      unsubscribe = db.collection('vehiculos')
        .where('active', '==', true)
        .onSnapshot(querySnapshot => {
          const items = []
          querySnapshot.forEach(doc => {
            items.push(doc.data())
          })
          commit('SET_VEHICULOS', items)
        }, error => {
          // console.log(error)
          throw new Error(error)
        })
    },

    addVehiculo(ctx, payload) {
      console.log(blankVehiculo)
      console.log(payload)
    },

    updateVehiculo(ctx, vehiculo) {
      return new Promise((resolve, reject) => {
        const db = firebase.firestore()
        db.collection('vehiculos')
          .doc(`${vehiculo.id}`)
          .update(vehiculo)
          .then(() => {
            // console.log(pedido.id)
            resolve(true)
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    UNSUSCRIBE() {
      if (unsubscribe) unsubscribe()
    },
  },
}
