import 'firebase/firestore'

// const blankProduct = {
//   id: '',
//   fecha: null,
//   cliente_id: '',
//   cliente_nombre: '',
//   cliente_hub: '',
//   cliente_ruta: null,
//   cliente_dir_literal: '',
//   cliente_dir_calle: '',
//   cliente_dir_numero: null,
//   cliente_dir_cp: '',
//   cliente_dir_localidad: '',
//   cliente_lat: null,
//   cliente_lng: null,
//   status: 0,
//   is_incidencia: false,
//   is_descartado: false,
//   agente: '',
//   notas_admin: '',
//   notas_operativa: '',
//   productos: [],
// }
export default {
  namespaced: true,
  state: {
    cartItems: [],
    cliente: null,
    processShop: 0,
  },
  getters: {
    cartItemsCount(state) {
      return state.cartItems.length
    },
    cartItemsSort(state) {
      const items = state.cartItems
      if (!items.length) return []
      return items.sort((o1, o2) => {
        if (o1.id > o2.id) { // comparación lexicogŕafica
          return 1
        } if (o1.id < o2.id) {
          return -1
        }
        return 0
      })
    },
  },
  mutations: {
    CHANGE_USD_ITEMS(state, payload) {
      const { cartItems } = state
      // const idx = cartItems.indexOf(payload.id)
      // cartItems[idx].uds = payload.uds
      // state.cartItems = cartItems
      const noSelec = cartItems.filter(c => c.id !== payload.id)
      noSelec.push(payload)
      state.cartItems = noSelec
    },
    SET_PROCESS(state, payload) {
      state.processShop = payload
    },
    SET_CLIENT(state, payload) {
      state.cliente = payload
    },
    SET_ITEMS(state, payload) {
      state.cartItems = payload
    },
    ADD_ITEM(state, payload) {
      state.cartItems.push(payload)
    },
  },
  actions: {
    resetData(ctx) {
      localStorage.removeItem('cartItems')
      ctx.commit('SET_CLIENT', null)
      ctx.commit('SET_ITEMS', [])
      ctx.commit('SET_PROCESS', 0)
    },
    selectClient(ctx, payload) {
      // console.log(payload)
      ctx.commit('SET_CLIENT', payload)
      ctx.commit('SET_PROCESS', 2)
    },
    getCartItems({ dispatch }) {
      const cartItems = JSON.parse(localStorage.getItem('cartItems')) || []
      // commit('SET_ITEMS', cartItems)
      dispatch('saveCartItems', cartItems)
    },

    addCartItem(ctx, payload) {
      // console.log(payload)
      const producto = payload
      // producto.isInCart = true
      producto.uds = 1
      const { cartItems } = ctx.state
      cartItems.push(producto)
      ctx.dispatch('saveCartItems', cartItems)
      // ctx.commit('SET_ITEMS', cartItems)
      // localStorage.setItem('cartItems', JSON.stringify(cartItems))
    },

    changeUdsCartItem(ctx, payload) {
      const { cartItems } = ctx.state
      const noSelec = cartItems.filter(c => c.id !== payload.id)
      noSelec.push(payload)
      ctx.dispatch('saveCartItems', noSelec)
    },

    removeProductFromCart(ctx, payload) {
      const { cartItems } = ctx.state
      const newCartItems = cartItems.filter(c => c.id !== payload)
      ctx.dispatch('saveCartItems', newCartItems)
    },

    saveCartItems(ctx, payload) {
      // console.log(payload)
      localStorage.setItem('cartItems', JSON.stringify(payload))
      ctx.commit('SET_ITEMS', payload)
    },
  },
}
