/* eslint-disable no-restricted-syntax */
import firebase from 'firebase/app'
import 'firebase/firestore'
import { getNewPedidoId, consolear } from '@/libs/utils-pedidos'

const PEDIDOS_TABLA = process.env?.NODE_ENV === 'development' ? 'fake-pedidos' : 'pedidos'

const blankProduct = {
  id: '',
  fecha: null,
  cliente_id: '',
  cliente_nombre: '',
  cliente_hub: '',
  cliente_ruta: null,
  cliente_dir_literal: '',
  cliente_dir_calle: '',
  cliente_dir_numero: null,
  cliente_dir_cp: '',
  cliente_dir_localidad: '',
  cliente_lat: null,
  cliente_lng: null,
  status: 0,
  is_incidencia: false,
  is_descartado: false,
  notas_admin: '',
  notas_operativa: '',
  productos: [],
}
let unsubscribe = null
let unsubscribePicking = null
// let unsubscribeAgent = null
export default {
  namespaced: true,
  state: {
    // pedidosAgente: [],
    pedidos: [],
    pedidosPicking: [],
    pedidosHoyControl: [],
    fechaDesdeLocal: null,
    fechaHastaLocal: null,
  },
  getters: {},
  mutations: {
    // SET_PEDIDOS_AGENTE(state, payload) {
    //   state.pedidosAgente = payload
    // },
    SET_PEDIDOS(state, payload) {
      state.pedidos = payload
    },
    SET_PEDIDOS_PICKING(state, payload) {
      state.pedidosPicking = payload
    },
    SET_FECHA_DESDE(state, payload) {
      state.fechaDesdeLocal = payload
    },
    SET_FECHA_HASTA(state, payload) {
      state.fechaHastaLocal = payload
    },
    SET_PEDIDOS_CONTROL(state, payload) {
      state.pedidosHoyControl = payload
    },
  },
  actions: {
    // hoy = 1618869600000
    // ahora = 1618906648839
    getPedidoByAgent(ctx, agenteId) {
      return new Promise((resolve, reject) => {
        const db = firebase.firestore()
        db.collection(PEDIDOS_TABLA)
          .where('agente', '==', agenteId)
          .orderBy('fecha', 'desc')
          .limit(100)
          .get()
          .then(querySnapshot => {
            const pedidos = []
            querySnapshot.forEach(doc => {
              pedidos.push(doc.data())
            })
            resolve(pedidos)
            // commit('SET_PEDIDOS_AGENTE', pedidos)
            // dispatch('getPedidosControl')
          })
          .catch(error => {
            consolear('Error getting documents: ', error)
            reject(error)
          })
      })
    },
    getPedidoById(ctx, id) {
      return new Promise((resolve, reject) => {
        const db = firebase.firestore()
        db.collection(PEDIDOS_TABLA)
          .doc(`${id}`)
          .get()
          .then(doc => {
            if (doc.exists) {
              resolve(doc.data())
            } else {
              resolve(null)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getPedidos({
      rootState, state, commit,
    }) {
      // const fechaHoy = new Date().toDateString()
      // const condicion = new Date(fechaHoy).getTime()
      const { fechaDesde, fechaHasta } = rootState.app
      const { fechaDesdeLocal, fechaHastaLocal } = state
      if (unsubscribe) {
        if (fechaDesdeLocal === fechaDesde && fechaHastaLocal === fechaHasta) return
        unsubscribe()
      }
      // consolear('fechaDesde: ', fechaDesde)
      // consolear('fechaHasta: ', fechaHasta)
      commit('SET_FECHA_DESDE', fechaDesde)
      commit('SET_FECHA_HASTA', fechaHasta)
      const db = firebase.firestore()
      unsubscribe = db.collection(PEDIDOS_TABLA)
        // .where('status', '<', 15)
        // .where('fecha', '>', condicion)
        .where('fecha', '>=', fechaDesde)
        .where('fecha', '<=', fechaHasta)
        .onSnapshot(querySnapshot => {
          const pedidos = []
          querySnapshot.forEach(doc => {
            pedidos.push(doc.data())
          })
          commit('SET_PEDIDOS', pedidos)
          // dispatch('getPedidosControl')
        })
      // , error => {
      //   consolear(error)
      // })
    },

    getPedidosPicking({
      commit,
    }) {
      if (unsubscribePicking) {
        unsubscribePicking()
      }
      consolear(`Buscar pedidos en ${PEDIDOS_TABLA}`)
      const db = firebase.firestore()
      unsubscribePicking = db.collection(PEDIDOS_TABLA)
        .where('status', '<', 3)
        .onSnapshot(querySnapshot => {
          const pedidos = []
          querySnapshot.forEach(doc => {
            pedidos.push(doc.data())
          })
          consolear(pedidos)
          commit('SET_PEDIDOS_PICKING', pedidos)
          // dispatch('getPedidosControl')
        })
      // , error => {
      //   consolear(error)
      // })
    },

    // getPedidosControl({ rootState, commit }) {
    //   const { fechaHoy } = rootState.app
    //   if (unsubscribeControl) return
    //   const db = firebase.firestore()
    //   unsubscribeControl = db.collection('pedidos-control').doc(`${fechaHoy}`)
    //     .onSnapshot(doc => {
    //       const datos = doc.data()
    //       const pc = datos ? datos.lista : []
    //       commit('SET_PEDIDOS_CONTROL', pc)
    //     })
    // },

    addPedido(ctx, payload) {
      const { cliente, productos, agente } = payload
      const fecha = new Date().getTime()
      const id = getNewPedidoId(fecha, cliente.id)
      const datos = JSON.parse(JSON.stringify(blankProduct))
      datos.id = id
      datos.productos = productos
      datos.fecha = fecha
      datos.agente = agente ? agente.id : null
      // eslint-disable-next-line no-restricted-syntax
      // eslint-disable-next-line guard-for-in
      for (const key in cliente) {
        datos[`cliente_${key}`] = cliente[key]
      }
      return new Promise((resolve, reject) => {
        const db = firebase.firestore()
        db.collection(PEDIDOS_TABLA)
          .doc(`${id}`)
          .set(datos)
          .then(() => {
            // consolear(id)
            // Guarda en pedidos-control
            // ctx.dispatch('addPedidosControl', id)
            resolve(true)
          })
          .catch(error => {
            // consolear(error)
            reject(error)
          })
      })
    },

    // addPedidosControl({ rootState, state }, payload) {
    //   const { fechaHoy } = rootState.app
    //   const { pedidosControl } = state
    //   const db = firebase.firestore()
    //   const pc = db.collection('pedidos-control').doc(`${fechaHoy}`)
    //   if (pedidosControl.length) {
    //     // Update
    //     pc.update({
    //       lista: firebase.firestore.FieldValue.arrayUnion(payload),
    //     })
    //   } else {
    //     // Create
    //     pc.set({
    //       lista: [payload],
    //     })
    //   }
    // },

    updatePedido(ctx, pedido) {
      return new Promise((resolve, reject) => {
        const db = firebase.firestore()
        db.collection(PEDIDOS_TABLA)
          .doc(`${pedido.id}`)
          .update(pedido)
          .then(() => {
            // consolear(pedido.id)
            resolve(true)
          })
          .catch(error => {
            // consolear(error)
            reject(error)
          })
      })
    },

    UNSUSCRIBE_PEDIDOS() {
      if (unsubscribe) unsubscribe()
      if (unsubscribePicking) unsubscribePicking()
    },
  },
}
