import Vue from 'vue'
// import * as VueGoogleMaps from 'vue2-google-maps'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import firebase from 'firebase/app'
import i18n from '@/libs/i18n'
// import VuePlaceAutocomplete from 'vue-place-autocomplete'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import '@/libs/firebase'

import 'firebase/auth'

// Axios Mock Adapter
import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
// Vue.use(VuePlaceAutocomplete)

// Composition API
Vue.use(VueCompositionAPI)

// Google Maps API
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyC3EYDV1IM_OjkKBr4qZtf42A3g43F8azY',
//     libraries: 'directions',
//     installComponents: false,
//   },
// })

// console.log(VueGoogleMaps)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

firebase.auth().onAuthStateChanged(() => {
  store.dispatch('auth/checkIsLogin')
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
  }).$mount('#app')
})
