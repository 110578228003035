// import firebase from 'firebase/app'
// import 'firebase/firestore'

export const consolear = loQueSea => {
  const isDev = process.env?.NODE_ENV === 'development'
  if (isDev) console.log(loQueSea)
}

export const zfill = (number, width) => {
  const numberOutput = Math.abs(number) /* Valor absoluto del número */
  const { length } = number.toString() /* Largo del número */
  const zero = '0' /* String de cero */

  if (width <= length) {
    if (number < 0) {
      return (`-${numberOutput.toString()}`)
    }
    return numberOutput.toString()
  }
  if (number < 0) {
    return (`-${zero.repeat(width - length)}${numberOutput.toString()}`)
  }
  return ((zero.repeat(width - length)) + numberOutput.toString())
}

export const getRandomInt = (min, max) => {
  if (min > max) {
    const temp = max
    /* eslint-disable no-param-reassign */
    max = min
    min = temp
    /* eslint-enable */
  }

  if (min <= 0) {
    return Math.floor(Math.random() * (max + Math.abs(min) + 1)) + min
  }
  return Math.floor(Math.random() * max) + min
}

export const getNewPedidoId = (fecha, cliente) => {
  const dc = `${zfill(getRandomInt(1, 10000), 4)}`
  return `${fecha}C${cliente}R${dc}`
}

export const reducer = (accumulator, currentValue) => accumulator + currentValue

export const fechaHoraCortas = f => {
  const fecha = new Date(f) || new Date()
  return new Intl.DateTimeFormat('es-ES', { dateStyle: 'short', timeStyle: 'short' }).format(fecha)
}

export const parserPedidosOld = pedidos => {
  const listaProductos = ['494736', '498393', '498394', '77211', '77389', '495129', '499062', '499055', '499052', '494847', '78314', '497499', '77183', '77018', '499011', '499227', '499012', '495252', '495256', '495251', '494595', '77961', '77184', '78318', '495004', '495007']
  const preHeaders = ['ID', 'AGENTE', 'FECHA_PEDIDO', 'FECHA_ENTREGA', 'CLIENTEID', 'NOMBRE', 'DIRECCION', 'RUTA', 'NOTAS_ADMIN', 'NOTAS_OPERATIVA', 'NOTAS_RIDER']
  const headers = preHeaders.concat(listaProductos)
  const results = []
  pedidos.forEach(pedido => {
    const { productos } = pedido
    // const ref494736 = productos.filter(p => p.ref === '494736')[0]
    // const uds494736 = ref494736 ? ref494736.uds : 0
    // const ref498393 = productos.filter(p => p.ref === '498393')[0]
    // const uds498393 = ref498393 ? ref498393.uds : 0
    // const ref498394 = productos.filter(p => p.ref === '498394')[0]
    // const uds498394 = ref498394 ? ref498394.uds : 0
    // const ref77211 = productos.filter(p => p.ref === '77211')[0]
    // const uds77211 = ref77211 ? ref77211.uds : 0
    // const ref77389 = productos.filter(p => p.ref === '77389')[0]
    // const uds77389 = ref77389 ? ref77389.uds : 0

    const fecha = new Date(pedido.fecha).toLocaleDateString()
    const hora = new Date(pedido.fecha).toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' })
    const fechaHora = `${fecha} ${hora}`
    let fechaHoraEntrega = ' - '
    if (pedido.delivered_at) {
      const fechaEntrega = new Date(pedido.delivered_at).toLocaleDateString()
      const horaEntrega = new Date(pedido.delivered_at).toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' })
      fechaHoraEntrega = `${fechaEntrega} ${horaEntrega}`
    }

    const dataPedido = {
      ID: pedido.id,
      AGENTE: pedido.agente || '',
      FECHA_PEDIDO: fechaHora,
      FECHA_ENTREGA: fechaHoraEntrega,
      CLIENTEID: pedido.cliente_id,
      NOMBRE: pedido.cliente_nombre,
      DIRECCION: pedido.cliente_dir_literal,
      RUTA: pedido.ruta,
      NOTAS_ADMIN: pedido.notas_admin,
      NOTAS_OPERATIVA: pedido.notas_operativa,
      NOTAS_RIDER: pedido.deliveredDetails ? `${pedido.deliveredDetails.motivoNoEntrega} ${pedido.deliveredDetails.notas}` : '',
    }

    listaProductos.forEach(refer => {
      const match = productos.filter(p => p.ref === refer)[0] || null
      dataPedido[refer] = match ? match.uds : 0
    })

    results.push(dataPedido)

    /* results.push({
      ID: pedido.id,
      AGENTE: pedido.agente || '',
      FECHA_PEDIDO: fechaHora,
      FECHA_ENTREGA: fechaHoraEntrega,
      CLIENTEID: pedido.cliente_id,
      NOMBRE: pedido.cliente_nombre,
      DIRECCION: pedido.cliente_dir_literal,
      RUTA: pedido.ruta,
      494736: uds494736,
      498393: uds498393,
      498394: uds498394,
      77211: uds77211,
      77389: uds77389,
      NOTAS_ADMIN: pedido.notas_admin,
      NOTAS_OPERATIVA: pedido.notas_operativa,
      NOTAS_RIDER: pedido.deliveredDetails ? `${pedido.deliveredDetails.motivoNoEntrega} ${pedido.deliveredDetails.notas}` : '',
    }) */
  })
  return {
    headers,
    results,
  }
}

// function productosList() {
//   return new Promise((resolve, reject) => {
//     const db = firebase.firestore()
//     db.collection('productos')
//       .where('active', '==', true)
//       .get()
//       .then(querySnapshot => {
//         const items = []
//         querySnapshot.forEach(doc => {
//           items.push(doc.id)
//         })
//         console.log(items)
//         resolve(items)
//       })
//       .catch(error => {
//         // throw new Error(error)
//         reject(error)
//       })
//   })
// }

export const parserPedidos = (pedidos, listaProductos) => {
  // const listaProductos = await productosList()
  // const listaProductos = ['494736', '498393', '498394', '77211', '77389', '495129', '499062', '499055', '499052', '494847', '78314', '497499', '77183', '77018', '499011', '499227', '499012', '495252', '495256', '495251', '494595', '77961', '77184', '78318', '495004', '495007']
  const preHeaders = ['ID', 'AGENTE', 'FECHA_PEDIDO', 'FECHA_ENTREGA', 'CLIENTEID', 'NOMBRE', 'DIRECCION', 'RUTA', 'RUTA_NOMBRE', 'RUTA_DESCRIPCION', 'RUTA_NOTAS', 'NOTAS_ADMIN', 'NOTAS_OPERATIVA', 'NOTAS_RIDER']
  const headers = preHeaders.concat(listaProductos)
  const results = []
  pedidos.forEach(pedido => {
    const { productos, rutaDetalle } = pedido

    const fecha = new Date(pedido.fecha).toLocaleDateString()
    const hora = new Date(pedido.fecha).toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' })
    const fechaHora = `${fecha} ${hora}`
    let fechaHoraEntrega = ' - '
    if (pedido.delivered_at) {
      const fechaEntrega = new Date(pedido.delivered_at).toLocaleDateString()
      const horaEntrega = new Date(pedido.delivered_at).toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' })
      fechaHoraEntrega = `${fechaEntrega} ${horaEntrega}`
    }

    const dataPedido = {
      ID: pedido.id,
      AGENTE: pedido.agente || '',
      FECHA_PEDIDO: fechaHora,
      FECHA_ENTREGA: fechaHoraEntrega,
      CLIENTEID: pedido.cliente_id,
      NOMBRE: pedido.cliente_nombre,
      DIRECCION: pedido.cliente_dir_literal,
      RUTA: pedido.ruta,
      RUTA_NOMBRE: rutaDetalle ? rutaDetalle.nombre : ' - ',
      RUTA_DESCRIPCION: rutaDetalle ? rutaDetalle.description || ' - ' : ' - ',
      RUTA_NOTAS: rutaDetalle ? rutaDetalle.notas || ' - ' : ' - ',
      NOTAS_ADMIN: pedido.notas_admin,
      NOTAS_OPERATIVA: pedido.notas_operativa,
      NOTAS_RIDER: pedido.deliveredDetails ? `${pedido.deliveredDetails.motivoNoEntrega} ${pedido.deliveredDetails.notas}` : '',
    }

    listaProductos.forEach(refer => {
      const match = productos.filter(p => p.ref === refer)[0] || null
      dataPedido[refer] = match ? match.uds : 0
    })

    results.push(dataPedido)
  })
  return {
    headers,
    results,
  }
}

export const parserRutas = rutas => {
  const headers = ['ID', 'NOMBRE', 'FECHA_COMIENZO', 'FECHA_FIN', 'TIEMPO_REAL_SEGUNDOS', 'RIDER_ID', 'RIDER_NOMBRE', 'VEHICULO_ID', 'VEHICULO_TIPO', 'DISTANCIA_METROS', 'DURACION_SEGUNDOS', 'MAPA']
  const results = []
  rutas.forEach(ruta => {
    const { calculated } = ruta
    const { duracionTotal, distanciaTotal, link } = calculated

    const fechaComienzo = ruta.started_ad ? `${new Date(ruta.started_ad).toLocaleDateString()} ${new Date(ruta.started_ad).toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' })}` : ' - '
    const fechaFin = ruta.ended_at ? `${new Date(ruta.ended_at).toLocaleDateString()} ${new Date(ruta.ended_at).toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' })}` : ' - '

    const tiempoReal = ruta.started_ad && ruta.ended_at ? Math.round((ruta.ended_at - ruta.started_ad) / 1000) : ' - '

    results.push({
      ID: ruta.id,
      NOMBRE: ruta.nombre || '',
      FECHA_COMIENZO: fechaComienzo,
      FECHA_FIN: fechaFin,
      TIEMPO_REAL_SEGUNDOS: tiempoReal,
      RIDER_ID: ruta.rider_id,
      RIDER_NOMBRE: ruta.rider_nombre,
      VEHICULO_ID: ruta.vehiculo_id,
      VEHICULO_TIPO: ruta.vehiculo_tipo,
      DISTANCIA_METROS: distanciaTotal,
      DURACION_SEGUNDOS: duracionTotal,
      MAPA: link,
    })
  })
  return {
    headers,
    results,
  }
}
