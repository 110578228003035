export default [
  {
    path: '/access-control',
    name: 'access-control',
    component: () => import('@/views/extensions/acl/AccessControl.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/test/Test.vue'),
    meta: {
      resource: 'Auth',
      action: 'write',
    },
  },
]
