import firebase from 'firebase/app'
import 'firebase/firestore'
import { paginateArray, sortCompare } from '@/libs/filter-data'

let unsubscribe = null
export default {
  namespaced: true,
  state: {
    clientes: [],
  },
  getters: {
    clientes: state => payload => {
      let clients = state.clientes
      const result = {
        users: [],
        total: 0,
      }
      if (!clients.length) return result
      const {
        ruta, perPage, currentPage, q, sortBy, sortDesc,
      } = payload
      if (ruta && ruta < 9999) clients = clients.filter(c => c.ruta === ruta)
      const queryLowered = q.toLowerCase()
      clients = clients.filter(
        cliente =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          cliente.id.toLowerCase().includes(queryLowered) || cliente.nombre.toLowerCase().includes(queryLowered) || cliente.dir_literal.toLowerCase().includes(queryLowered) || cliente.hub.toLowerCase().includes(queryLowered),
      )
      // (cliente.id.toLowerCase().includes(queryLowered) || cliente.nombre.toLowerCase().includes(queryLowered) || cliente.dir_literal.toLowerCase().includes(queryLowered)) && cliente.ruta === (ruta || cliente.ruta),
      /* eslint-enable  */

      const sortedData = clients.sort(sortCompare(sortBy))
      if (sortDesc) sortedData.reverse()

      // result = {
      //   users: paginateArray(sortedData, perPage, currentPage),
      //   total: clients.length,
      // }
      result.users = paginateArray(sortedData, perPage, currentPage)
      result.total = clients.length
      return result
    },
  },
  mutations: {
    SET_CLIENTES(state, payload) {
      state.clientes = payload
    },
  },
  actions: {
    addCliente(ctx, payload) {
      const { id } = payload
      return new Promise((resolve, reject) => {
        const db = firebase.firestore()
        db.collection('clientes')
          .doc(`${id}`)
          .set(payload)
          .then(() => {
            resolve(true)
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },
    getClientes({ commit }) {
      if (unsubscribe) return
      const db = firebase.firestore()
      unsubscribe = db.collection('clientes')
        .onSnapshot(querySnapshot => {
          const clientes = []
          querySnapshot.forEach(doc => {
            clientes.push(doc.data())
          })
          commit('SET_CLIENTES', clientes)
        }, () => {
          // console.log(error)
        })
    },

    getClientesOnce({ state, commit }) {
      const { clientes } = state
      if (clientes.length) return
      const db = firebase.firestore()
      db.collection('clientes')
        .get()
        .then(querySnapshot => {
          const items = []
          querySnapshot.forEach(doc => {
            items.push(doc.data())
          })
          commit('SET_CLIENTES', items)
        })
        .catch(error => {
          throw new Error(error)
        })
    },

    UNSUSCRIBE_CLIENTES() {
      if (unsubscribe) unsubscribe()
    },
  },
}
