/* eslint-disable no-restricted-syntax */
import firebase from 'firebase/app'
import 'firebase/firestore'

const blankProduct = {
  id: '',
  sku: '',
  name: '',
  description: '',
  slug: '',
  pictures: [
    {
      link: '',
      isActive: true,
      isPrimary: true,
    },
  ],
  price: 0,
  discount: 0,
  active: true,
  supplierId: 'bimbo',
  supplierRef: '',
  categoryId: '',
  quantityPerUnit: 1,
  sizeLength: 0,
  sizeWidth: 0,
  sizeHeight: 0,
  weight: 0,
  unitInStock: 0,
  unitSupplierOrder: 0,
  unitCustomerOrder: 0,
  ranking: 0,
  notes: '',
}
let unsubscribe = null
let unsubscribeIventarios = null
let unsubscribeEntradas = null
export default {
  namespaced: true,
  state: {
    productos: [],
    inventarios: [],
    entradas: [],
  },
  getters: {},
  mutations: {
    SET_PRODUCTOS(state, payload) {
      state.productos = payload
    },
    SET_INVENTARIOS(state, payload) {
      state.inventarios = payload
    },
    SET_ENTRADAS(state, payload) {
      state.entradas = payload
    },
  },
  actions: {
    getProductosOnce({ state, commit }) {
      const { productos } = state
      if (productos.length) return
      const db = firebase.firestore()
      db.collection('productos')
        .where('active', '==', true)
        .get()
        .then(querySnapshot => {
          const items = []
          querySnapshot.forEach(doc => {
            items.push(doc.data())
          })
          commit('SET_PRODUCTOS', items)
        })
        .catch(error => {
          throw new Error(error)
        })
    },
    getProductos({ commit }) {
      if (unsubscribe) return
      const db = firebase.firestore()
      unsubscribe = db.collection('productos')
        .where('active', '==', true)
        .onSnapshot(querySnapshot => {
          const items = []
          querySnapshot.forEach(doc => {
            items.push(doc.data())
          })
          commit('SET_PRODUCTOS', items)
        }, error => {
          // console.log(error)
          throw new Error(error)
        })
    },

    addProducto(ctx, payload) {
      console.log(blankProduct)
      console.log(payload)
    },

    updateProducto(ctx, producto) {
      return new Promise((resolve, reject) => {
        const db = firebase.firestore()
        db.collection('productos')
          .doc(`${producto.id}`)
          .update(producto)
          .then(() => {
            // console.log(pedido.id)
            resolve(true)
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    /**
     *
     * INVENTARIOS
     */
    getInventarios({ commit }) {
      if (unsubscribeIventarios) return
      const db = firebase.firestore()
      unsubscribeIventarios = db.collection('inventario')
        .onSnapshot(querySnapshot => {
          const items = []
          querySnapshot.forEach(doc => {
            items.push(doc.data())
          })
          commit('SET_INVENTARIOS', items)
        }, error => {
          // console.log(error)
          throw new Error(error)
        })
    },

    setInventarios(ctx, payload) {
      return new Promise((resolve, reject) => {
        const db = firebase.firestore()
        const ref = db.collection('inventario').doc()
        // console.log(ref)
        const data = payload
        data.id = `${ref.id}`
        // console.log(data)
        ref
          .set(data)
          .then(() => {
            const batch = db.batch()
            data.productos.forEach(item => {
              const prodRef = db.collection('productos').doc(`${item.id}`)
              batch.update(prodRef, { unitInStock: item.uds })
            })
            batch.commit().then(() => {
              resolve(true)
            })
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    /**
     *
     * ENTRADAS DE PRODUCTOS AL ALMACEN
     */
    getEntradas({ commit }) {
      if (unsubscribeEntradas) return
      const db = firebase.firestore()
      unsubscribeEntradas = db.collection('entrada-productos')
        .onSnapshot(querySnapshot => {
          const items = []
          querySnapshot.forEach(doc => {
            items.push(doc.data())
          })
          commit('SET_ENTRADAS', items)
        }, error => {
          // console.log(error)
          throw new Error(error)
        })
    },

    setEntradas(ctx, payload) {
      return new Promise((resolve, reject) => {
        const db = firebase.firestore()
        const ref = db.collection('entrada-productos').doc()
        // console.log(ref)
        const data = payload
        data.id = `${ref.id}`
        // console.log(data)
        ref
          .set(data)
          .then(() => {
            const batch = db.batch()
            data.productos.forEach(item => {
              const prodRef = db.collection('productos').doc(`${item.id}`)
              batch.update(prodRef, { unitInStock: firebase.firestore.FieldValue.increment(item.uds) })
            })
            batch.commit().then(() => {
              resolve(true)
            })
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    UNSUSCRIBE() {
      if (unsubscribe) unsubscribe()
    },
    UNSUSCRIBEIVENTARIOS() {
      if (unsubscribeIventarios) unsubscribeIventarios()
    },
    UNSUSCRIBEENTRADAS() {
      if (unsubscribeEntradas) unsubscribeEntradas()
    },
  },
}
