import ability from './ability'

export const canNavigate = to => to.matched.some(route => ability.can(route.meta.action || 'read', route.meta.resource))
/* export const canNavigate = to => {
  console.log(to)
  console.log(ability)
  return to.matched.some(route => {
    console.log(`route.meta.action: ${route.meta.action}, route.meta.resource: ${route.meta.resource}`)
    // route.meta.action: write, route.meta.resource: Shop
    return ability.can(route.meta.action || 'read', route.meta.resource)
  })
} */

export const _ = undefined
