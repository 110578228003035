import firebase from 'firebase/app'

// Initialize Firebase
const config = {
  apiKey: 'AIzaSyDdEJCNJ0WW14qwjj2gJTFNDR_4zMkR4Sc',
  authDomain: 'cargo-bici.firebaseapp.com',
  databaseURL: 'https://cargo-bici.firebaseio.com',
  projectId: 'cargo-bici',
  storageBucket: 'cargo-bici.appspot.com',
  messagingSenderId: '588149465950',
  appId: '1:588149465950:web:d81b9d2cf51fd41cdee5cf',
  measurementId: 'G-CCCJLG604R',
}

firebase.initializeApp(config)
// console.log(firebase)
